import { format } from 'date-fns'
import { Audit } from './types'
import { Audit as APIAudit } from '../../../hooks/useAudit/useAudit.type'

import { saveAs } from 'file-saver'
import { getUser } from '../../../common/utils/auth'
import { API_KEY, API_URL } from '../../../constants/global'

export const prepareAuditForAPI = (
   audit: Audit,
   requestType: string,
   removedAuditItemsIds: number[]
): APIAudit => {
   return {
      auditID: audit?.AuditID,
      auditedBy: audit?.AuditedByID,
      dueDate: audit?.DateDue
         ? format(new Date(audit?.DateDue), 'dd MMM yyyy')
         : null,
      isSendRequest: requestType === 'send',
      RemovedAuditItemsId: removedAuditItemsIds,
      itemsForAudit: audit?.AuditItems?.map((item) => ({
         auditItemID: audit?.Id || null,
         toolItemId: item.Id,
         toolLoanId: item.ToolLoanId,
         kitId: item.KitId,
         statusQuantity: item.StatusQuantity,
         status: item.Status,
         quantity: item.Quantity,
      })),
   }
}

export const downloadCsv = (
   path: string,
   fileName: string,
   id: number,
   showSpinnerMethod?: React.Dispatch<React.SetStateAction<boolean>>
) => {
   if (showSpinnerMethod) showSpinnerMethod(true)
   const user = getUser()?.access_token
   const req = new XMLHttpRequest()
   req.open('POST', API_URL + path, true)
   req.setRequestHeader('Ocp-Apim-Subscription-Key', API_KEY)
   req.setRequestHeader('Authorization', `Bearer ${user}`)
   req.setRequestHeader('Content-Type', 'application/json')

   req.onreadystatechange = function () {
      if (req.readyState == 4 && req.status == 200) {
         const blob = new Blob([req.response], { type: 'octet/stream' })
         saveAs(blob, `${fileName}_${id}.xlsx`)
         if (showSpinnerMethod) showSpinnerMethod(false)
      }
   }
   req.responseType = 'arraybuffer'
   req.send(
      JSON.stringify({
         offset: -1,
         auditID: id,
      })
   )
}
